import get from 'lodash/get';
import { escapeStringRegexp } from 'utils/strings';
/**
 * Devuelve si un elemento concuerda con el texto de búsqueda
 * @param   {Object}         args
 * @param   {Object}         args.item
 * @param   {string}         args.searchText
 * @param   {Array.<string>} args.keys
 * @returns {boolean}
 */
const isItemMatchingSearchText = ({ item, searchText, keys }) => {
  const escapedSearchText = escapeStringRegexp(searchText);
  const searchTextRegExp = new RegExp(escapedSearchText, 'i');

  const isMatching = keys.some(key => {
    const value = get(item, key);
    const valueAsString = value?.toString();
    return valueAsString && valueAsString.match(searchTextRegExp);
  });
  return isMatching;
};

const filterDataBySearchText = ({ data, searchText, keys }) => {
  if (!searchText) return data;
  return data?.filter(item => isItemMatchingSearchText({ item, searchText, keys }));
};

export { isItemMatchingSearchText, filterDataBySearchText };
