const appName = process.env.REACT_APP_NAME;
const appVersion = process.env.REACT_APP_VERSION;

const productId = process.env.REACT_APP_PRODUCT_ID;
const productAuthor = process.env.REACT_APP_PRODUCT_AUTHOR;
const productName = process.env.REACT_APP_PRODUCT_NAME;
const productColor = process.env.REACT_APP_PRODUCT_COLOR;

const originUrl = process.env.REACT_APP_ORIGIN_URL;
const baseUrl = `${originUrl}${process.env.REACT_APP_BASE_URL}`;

const mapboxApiKey = process.env.REACT_APP_MAPBOX_API_KEY;
const mapCenter = JSON.parse(process.env.REACT_APP_MAP_CENTER);
const mapZoom = JSON.parse(process.env.REACT_APP_MAP_ZOOM);

export {
  appName,
  appVersion,
  productId,
  productAuthor,
  productName,
  productColor,
  originUrl,
  baseUrl,
  mapCenter,
  mapZoom,
  mapboxApiKey,
};
