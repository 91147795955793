import { askPermission, unsubscribeUser } from 'utils/sw';

const requestNotificationPermission = () => {
  return askPermission();
};

const unsubscribeFromNotifications = () => {
  return unsubscribeUser();
};

export { requestNotificationPermission, unsubscribeFromNotifications };
