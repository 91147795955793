const registerServiceWorker = ({ scriptUrl = 'service-worker.js', options } = {}) => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
      navigator.serviceWorker.register(scriptUrl, options).then(registration => {
        console.log('Service worker registered.', registration);
      });
    });
  }
};

export { registerServiceWorker };
