import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { ReactFlowProvider } from 'reactflow';
import queryClient from 'clients/query';
import sanitize from 'utils/sanitize';
import theme from 'utils/theme';
import { SnackbarProvider } from 'context/SnackbarContext';
import { AuthProvider } from './context/AuthContext';

const GlobalStyle = createGlobalStyle`
  /**
   * Use sanitize style
   */

  ${sanitize}

  /**
   * Define CSS Variables using theme properties
   */

  :root {
    --mdc-theme-primary: ${props => props.theme.colors.green};
    --mdc-theme-secondary: ${props => props.theme.colors.black};
    --mdc-theme-error: ${props => props.theme.colors.error}
  }

  /**
   * 1. Fix for 100vh in mobile WebKit: https://css-tricks.com/css-fix-for-100vh-in-mobile-webkit/
   * 2. Improve scroll behavior
   */

  html {
    height: -webkit-fill-available; /* 1 */
    scroll-behavior: smooth; /* 2 */
  }

  body {
    min-height: 100vh; /* 1 */
    min-height: -webkit-fill-available; /* 1 */
    font-family: ${theme.fonts.body}
  }

  a {
    text-decoration: none;
    color: ${theme.colors.info};
  }

  button {
    font-family: ${theme.fonts.body};
  }
`;

const AppProviders = ({ children }) => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ReactFlowProvider>
          <SnackbarProvider>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <AuthProvider>
                <DndProvider backend={HTML5Backend}>{children}</DndProvider>
              </AuthProvider>
            </ThemeProvider>
          </SnackbarProvider>
        </ReactFlowProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default AppProviders;
