import React, { useContext, useCallback, useMemo } from 'react';
import queryClient from 'clients/query';
import * as auth from 'services/auth';
import useQueryAuthUser from 'hooks/useQueryAuthUser';
import ServerError from 'screens/ServerError';
import Loading from 'components/Loading';

// References:
// - https://kentcdodds.com/blog/authentication-in-react-applications
// - https://github.com/kentcdodds/bookshelf/blob/master/src/context/auth-context.js

const AuthContext = React.createContext();

const fetchLogout = async () => {
  const token = auth.getToken();
  const hasToken = Boolean(token);

  if (hasToken) {
    await auth.fetchLogout();
  }
  return await auth.logout();
};

const AuthProvider = props => {
  const { status, isIdle, isLoading, isSuccess, isError, data: user, refetch } = useQueryAuthUser();

  /**
   * En vez de `refetch` también se podría usar `queryClient.refetchQueries(queryKey)`
   * En react-query v2 parecía haber algunos problemas con el `refetchQueries`
   * @see: https://github.com/tannerlinsley/react-query/discussions/1363
   * @see: https://github.com/tannerlinsley/react-query/issues/1059
   */

  const login = useCallback(form => auth.login(form).then(refetch), [refetch]);

  const loginWithUsernameAndPassword = useCallback(
    form => auth.loginWithUsernameAndPassword(form).then(refetch),
    [refetch],
  );

  const loginWithOneTimePassword = useCallback(
    form => auth.loginWithOneTimePassword(form).then(refetch),
    [refetch],
  );

  const requestPassword = useCallback(form => auth.requestPassword(form), []);

  /**
   * En vez de `refetch` también se podría usar `queryClient.resetQueries()`
   * En react-query v2 parecía haber algunos problemas y en ocaciones no hacía un re-render el `useQueryAuthUser`...
   * @see: https://react-query.tanstack.com/reference/QueryClient#queryclientresetqueries
   * @see: https://github.com/tannerlinsley/react-query/discussions/1363
   * @see: https://github.com/tannerlinsley/react-query/issues/1059
   */
  const logout = useCallback(() => {
    fetchLogout().then(() => {
      refetch();
      queryClient.clear();
    });
  }, [refetch]);

  const value = useMemo(
    () => ({
      user,
      login,
      loginWithUsernameAndPassword,
      loginWithOneTimePassword,
      logout,
      requestPassword,
    }),
    [user, login, loginWithUsernameAndPassword, loginWithOneTimePassword, logout, requestPassword],
  );

  if (isIdle || isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ServerError />;
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />;
  }

  throw new Error(`Unhandled status ${status}`);
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) throw new Error(`useAuth must be used within a AuthProvider`);
  return context;
};

export { AuthProvider, useAuth };
