import React, { Suspense } from 'react';
import Loading from './components/Loading';
import { useAuth } from './context/AuthContext';

const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'));
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'));

const App = () => {
  const { user } = useAuth();

  return (
    <>
      <Suspense fallback={<Loading />}>
        {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </Suspense>
    </>
  );
};

export default App;
