/**
 * Elimina un elemento de la caché
 * @param {string} key
 * @returns {Promise}
 */
const removeCache = key => caches.delete(key);

/**
 * Elimina todos los elementos de la caché
 * @see https://developer.mozilla.org/en-US/docs/Web/API/CacheStorage/delete#examples
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Service_Worker_API/Using_Service_Workers#deleting_old_caches
 * @returns {Promise}
 */
const removeCaches = () => {
  if (!('serviceWorker' in navigator)) return Promise.resolve();

  return caches.keys().then(keys => {
    return Promise.all(keys.map(removeCache));
  });
};

export { removeCaches };
