const urlBase64ToUint8Array = base64String => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const sendSubscription = ({ subscription, url, headers }) => {
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(subscription),
    headers,
  });
};

// const sendUnsubscription = ({ subscription, url, headers }) => {
//   return fetch(url, {
//     method: 'POST',
//     body: JSON.stringify(subscription),
//     headers,
//   });
// };

const subscribeUser = ({ url, headers, publicVapidKey }) => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        if (!registration.pushManager) {
          console.log('Push manager unavailable.');
          return;
        }

        registration.pushManager.getSubscription().then(existedSubscription => {
          if (existedSubscription === null) {
            console.log('No subscription detected, make a request.');
            registration.pushManager
              .subscribe({
                applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
                userVisibleOnly: true,
              })
              .then(newSubscription => {
                console.log('New subscription added.');
                sendSubscription({ subscription: newSubscription, url, headers });
              })
              .catch(e => {
                if (Notification.permission !== 'granted') {
                  console.log('Permission was not granted.');
                } else {
                  console.error('An error ocurred during the subscription process.', e);
                }
              });
          } else {
            console.log('Existed subscription detected.');
            sendSubscription({ subscription: existedSubscription, url, headers });
          }
        });
      })
      .catch(e => {
        console.error('An error ocurred during Service Worker registration.', e);
      });
  }
};

const unsubscribeUser = ({ url, headers } = {}) => {
  navigator.serviceWorker.ready.then(registration => {
    registration.pushManager.getSubscription().then(subscription => {
      subscription
        .unsubscribe()
        .then(successful => {
          console.log('Successfully unsubscribed');
        })
        .catch(e => {
          console.log('Unsubscription failed');
        });
    });
  });
};

export { subscribeUser, unsubscribeUser };
