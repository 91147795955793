import React from 'react';
import styled from 'styled-components';
import MaintenanceIllustration from 'components/MaintenanceIllustration';

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
`;

const Illustration = styled.div`
  margin-bottom: 32px;
  height: 400px;
`;

const Explanation = styled.div`
  margin-bottom: 32px;
  max-width: 100ch;
  text-align: center;
`;

const Title = styled.h2`
  margin: 0 0 16px 0;
  font-size: 1.5rem;
  line-height: 1.2;
  color: ${props => props.theme.colors.secondary};
`;

const Paragraph = styled.p`
  margin: 0 0 32px 0;
  font-size: 1.125rem;
  color: ${props => props.theme.colors.black};
  &:last-child {
    margin-bottom: 0;
  }
`;

const ParagraphSecondary = styled(Paragraph)`
  margin: 0 0 4px 0;
  font-size: 1rem;
  line-height: 1.2;
  color: ${props => props.theme.colors.cadetBlue};
`;

const ServerError = () => {
  return (
    <Wrapper>
      <Content>
        <Illustration>
          <MaintenanceIllustration />
        </Illustration>
        <Explanation>
          <Title>Une erreur imprévue s'est produite</Title>
          <Paragraph>
            Nous avons le regret de vous informer que nous avons subi une interruption de service.
          </Paragraph>
          <ParagraphSecondary>
            Nous nous efforçons de résoudre le problème dans les plus brefs délais.
          </ParagraphSecondary>
          <ParagraphSecondary>
            En attendant, si vous avez des questions ou des préoccupations, n'hésitez pas à nous
            contacter.
          </ParagraphSecondary>
          <ParagraphSecondary>
            Merci pour votre compréhension et votre confiance.
          </ParagraphSecondary>
        </Explanation>
      </Content>
    </Wrapper>
  );
};

ServerError.propTypes = {};

export default ServerError;
