import React from 'react';
import styles from './MaintenanceIllustration.module.css';

const MaintenanceIllustration = props => {
  return (
    <svg
      className={styles.illustration}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="438"
      height="548"
      viewBox="0 0 438 548"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M11 263c6.281 27.332 15.853 94.896 25.723 121C52.875 379.701 61.25 334.863 78 326.878c-12.563-20.883-18.844-30.71-29.612-48.83C33.134 271.906 25.656 268.835 11 263zM424 255c-.623 32.82-7.481 105.686-17.143 137-11.221-13.851-17.454-62.327-30.857-77.985 8.104-17.764 15.273-35.83 21.195-54.197 10.909-1.807 16.208-3.011 26.805-4.818z"
          fill="#FF8B0E"
        />
        <path
          d="M73.913 312.595c8.129 9.054 12.042 54.631 20.471 63.987 9.032 21.732 18.063 43.464 26.793 65.497 18.665-9.356 36.426-20.222 53.887-31.994 13.245 7.848 20.169 11.772 33.415 19.016 8.73 11.771 13.246 17.808 21.675 29.579 10.838 1.811 16.557 2.415 27.395 3.32 7.526-26.863 14.751-54.027 21.073-81.192l21.675 21.732c7.526 6.942 11.439 10.564 18.664 17.204 3.312 5.735 4.817 8.753 8.129 14.488 11.74-29.278 22.277-99.604 31.91-129.485-10.536-15.997-15.955-24.146-27.094-40.747-43.049 7.546-86.098 15.091-129.448 21.732-31.308 4.829-110.18 1.509-132.458 26.863h3.913z"
          fill="#FF8B0E"
        />
        <path
          d="M.06 212.965c-.303 12.652.604 26.508 1.512 41.269.604 8.434 1.512 17.471 7.863 26.508 6.351 9.036 19.357 18.977 33.572 21.085 3.326 9.64 6.653 80.127 10.585 90.67 1.815 4.819 4.234 9.94 9.376 14.459 6.956 6.024 19.356 32.532 23.59 39.762 6.05-25.303 5.747-92.477 9.679-118.383 2.117 8.134 4.537 57.234 6.956 65.668 14.82 8.133 29.035 17.17 42.04 27.412 1.21-2.711 3.327-4.82 6.654-5.724 12.4 23.797 24.8 48.197 33.874 73.5 12.098 5.422 16.029 22.291 27.825 26.809 6.653-25.002 11.19-52.414 25.405-74.704 9.073 3.916 19.054-4.218 23.288-12.351 3.932-8.434 5.444-17.772 12.401-24.399 3.326 9.037 4.234 43.979 5.444 53.317 7.863.301 8.166 29.219 14.215 32.231 33.268-36.448 29.034-134.949 42.644-179.23 11.493 18.074 14.518 80.428 20.264 100.61 9.678-4.819 19.962-8.434 30.245-11.145 8.166-26.207 14.819-93.682 18.449-121.094.302-2.108 1.814-7.53 3.629-9.338 1.512-1.506 5.142-.301 10.888-3.012 6.352-3.012 8.166-9.037 9.376-14.459 2.42-11.446 7.864-23.496 8.166-34.038-31.757.301-63.513-3.314-94.363-5.121-20.869-1.205-43.25 0-64.118-2.109-15.425-1.506-31.152-5.723-46.274-7.832-13.61-1.807-27.22-1.807-40.831-2.108-48.088-1.205-96.177-3.314-143.358 4.217-16.03 2.711-32.967 6.326-48.694 6.627 0 .602-.303.602-.303.903z"
          fill="url(#paint0_radial)"
        />
        <path
          d="M418 228.129s-.584 37.442-1.753 37.442c-.876.286-6.135 1.429-6.135 1.429-1.168-7.717-2.045-11.719-3.797-19.436-5.843-2-8.764-3.144-14.315-5.144 0 0 16.652-16.006 26-14.291zM279.053 403.379c2.933 8.112 3.52 42.36 4.987 50.772 4.693-6.009 11.733-36.952 17.6-42.059-3.52-7.511-8.507-14.421-14.374-20.429-.586.601-1.466 1.202-2.053 1.502-2.64 4.206-5.28 8.112-8.213 12.317.586-.6 1.466-1.201 2.053-2.103zM320.413 418.701c-4.987 1.502-10.267 1.803-15.253 1.502-2.934 3.906-7.627 33.648-9.974 37.853 3.52.902 0 29.141 2.934 30.644 11.44-21.631 14.96-46.866 22.293-69.999zM25.33 271.473c1.161-1.222.58-3.054 0-4.581-5.52-13.744-8.715-26.266-9.877-38.483-.581-6.109-.581-12.522-3.777-17.409-3.776.305-7.552.611-11.329.611 0 0-.29 0-.29.305-.29 12.828.58 26.877 1.452 41.843.581 6.108 1.162 12.522 3.486 19.241H20.1c2.034-.305 4.067-.611 5.23-1.527z"
          fill="#E07500"
        />
        <path
          d="M412 244c-4.838 19.966-12.399 39.933-18.749 58.387-.907 2.117-42.639 13.613-42.639 13.613-10.282-14.824-15.725-22.387-26.612-37.815 6.048-13.009 8.77-19.361 14.213-31.765 24.192-.302 48.99-1.21 73.787-2.42z"
          fill="#FF9726"
        />
        <path
          d="M359 276.2c-.839 15-1.677 22.5-3.355 37.8-7.269-6.9-10.623-10.5-17.892-17.7-1.678-8.4-2.796-12.6-4.753-21.3 10.344.6 15.656.9 26 1.2z"
          fill="#E07500"
        />
        <path
          d="M357.167 408.702c4.005-8.647 3.719-82.29-.572-90.042-4.577-8.647-12.586-15.802-20.595-22.66.572 3.876.858 5.665 1.43 9.541.858 2.385 1.716 4.77 2.289 7.156 9.439 16.994 11.727 77.519 16.59 96.303 0 0 .286-.298.858-.298z"
          fill="#E07500"
        />
        <path
          d="M8.637 228s-1.205 19.929-.3 23.408C9.24 254.888 24 259 24 259c-.602-6.01-.904-9.173-1.205-14.867-6.326-6.96-9.037-10.123-14.158-16.133z"
          fill="#FF9726"
        />
        <path
          d="M22.605 230.786c-4.184-1.393-8.09-2.786-12.553-2.786-.279 3.9.558 7.801 2.51 11.98.28.279.28.557.559.836.837.557 1.952.836 3.068.836 1.395 0 2.79.278 4.185.278.837 0 1.952.279 2.51-.278.558-.279.558-.836.558-1.393.279-3.065.279-6.13.558-9.194-.558.278-.837-.279-1.395-.279z"
          fill="#FFDBB5"
        />
        <path
          d="M56 253c1.807 15.91 3.012 32.119 3.915 48.629 10.24 4.203 20.78 8.405 31.02 12.608 6.325 2.401 12.649 5.103 17.468 9.305 4.819 4.203 7.83 51.031 5.421 55.534 12.348 3.302 24.696 6.604 37.345 9.606.301 5.103 1.505 10.206 3.312 15.309 7.831 0 15.661 0 23.491-.3 2.41 0 4.518-.3 6.626-1.201 2.108-.9 3.313-2.702 4.819-4.202 4.818-6.304 9.938-12.608 14.757-18.912-.301 12.608 1.506 25.816 4.517 38.423 11.144.601 22.287 1.201 33.43 1.201 3.012 0 6.023 0 8.433-1.501 1.505-.9 2.71-2.702 3.614-4.202 7.228-11.407 14.456-22.814 21.382-34.221 6.927-.3 14.155-.901 21.082-1.501 2.409 4.503 4.216 9.005 5.12 13.808 11.444-3.602 18.672-53.432 24.093-63.038 8.433-15.009 1.807-25.515 14.155-32.119 0-1.501-1.205-5.403-1.506-6.904-35.236-8.105-71.677-10.506-107.516-13.208-57.523-4.503-115.046-11.707-172.268-20.412-1.505-1.201-1.806-1.501-2.71-2.702z"
          fill="#FF9726"
        />
        <path
          d="M217.429 307c-4.572 14.387-6.858 62.045-11.429 76.133 2 14.687 3.143 21.88 5.143 36.867 7.143-21.281 14-83.326 20.857-104.607-6-3.298-8.857-5.096-14.571-8.393z"
          fill="#E07500"
        />
        <path
          d="M158.193 312c-.257 20.932-.257 71.768 0 93h3.345c2.83-20.633 4.374-71.17 7.462-91.804-4.374-.598-6.433-.598-10.807-1.196z"
          fill="#FFB15B"
        />
        <path
          d="M102 292s0 15.653 2.979 19.951c2.979 4.297 9.209 11.049 9.209 11.049 5.416-7.673 8.124-11.663 13.812-19.951-10.292-4.603-15.438-6.752-26-11.049z"
          fill="#E07500"
        />
        <path
          d="M122.871 288.074c-7.731-.284-15.152.284-22.264 1.137-1.547 2.842 0 6.536 3.092 9.094 8.658.853 17.317 2.274 25.975 3.695 1.546-3.41 1.856-7.105.309-10.8-2.164-1.421-4.02-3.126-7.112-3.126.309 0 .309 0 0 0z"
          fill="#FFC78B"
        />
        <path
          d="M139.111 136.871c-7.251-.3-14.804.898-22.659 3.294l-.906 6.29c-9.97 0-20.544 2.396-31.722 6.589-7.855 2.995-18.731 8.686-19.638 14.676-.302 1.498.302 2.696-.906 4.493-1.51 2.096-4.834 3.294-7.553 4.193-5.74 2.097-12.085 5.691-14.804 9.584-3.021 3.894-.604 8.387 5.136 7.488-12.99 4.193-26.284 8.087-39.577 11.382-2.417.599-4.834 1.497-6.042 2.695-2.115 2.696 3.927 3.295 6.948 4.493 6.647 2.695 3.626 11.082 11.783 13.478 1.813.599 3.927.599 5.136 1.797 1.51 1.498.604 3.594-.302 5.391-2.417 6.29 2.417 13.778 11.48 17.372 6.345 2.695 14.804 3.294 19.034 8.087 2.718 2.995 3.02 7.487 7.552 9.584 4.834 2.396 11.48 0 16.315 2.696 3.625 1.797 4.532 5.391 7.25 7.787 4.532 4.493 12.69 5.391 19.94 5.092 6.949-.3 14.502-1.498 21.148.898 6.647 2.396 10.877 10.783 5.136 14.077 6.647 0 12.991-.299 19.638-.599-.604 5.691 4.532 11.681 10.876 14.676 6.345 2.995 13.898 4.193 20.846 4.792 7.251.899 14.804 1.498 21.451-.599 6.646-2.096 12.386-7.487 11.48-13.777 6.042.299 9.97 6.29 15.408 8.386 3.021 1.198 6.647 1.198 9.97.899 34.139-2.097 66.768-8.986 100.001-18.57.604-7.488-3.324-15.275-10.575-20.367 27.191 5.092 58.007-2.096 77.342-21.265 2.719-2.696 4.834-6.589 2.417-9.584-6.646-.6-13.595-1.498-20.242-2.397 7.251-6.888 16.315-12.28 26.889-15.275 8.157-2.096 17.825-3.294 20.242-8.985 2.115-5.391-4.532-12.579-12.387-16.773-8.157-4.193-16.919-6.289-25.378-7.787-4.834-.898-10.574-2.097-13.897-5.391-1.511-1.498-2.719-3.295-3.324-5.092-3.021-5.99-6.042-12.28-11.178-17.971-5.136-5.69-12.991-11.381-19.637-12.279-2.72-.3-5.439.299-8.158-.599-4.229-1.199-8.157-4.493-12.084-7.189-14.502-9.584-29.306-7.787-43.505-11.98-10.876-2.995-21.45-9.884-31.722-10.783-7.553-.599-15.106 2.097-22.659.599-5.74-1.198-11.179-4.792-16.919-5.99-13.293-2.695-25.378 8.686-38.973 13.778-3.323 1.198-6.949 2.096-9.668 1.198-2.417-.599-4.229-2.396-6.344-3.295-6.042-2.396-15.408 2.696-19.638 9.884-1.812 0-2.114.3-3.021.899z"
          fill="url(#paint1_radial)"
        />
        <path
          d="M325 189c-22.644 0-41-5.373-41-12s18.356-12 41-12 41 5.373 41 12-18.356 12-41 12z"
          fill="#FFC78B"
        />
        <path
          d="M325.5 188c-21.815 0-39.5-5.149-39.5-11.5s17.685-11.5 39.5-11.5 39.5 5.149 39.5 11.5-17.685 11.5-39.5 11.5z"
          fill="url(#paint2_radial)"
        />
        <path
          d="M418 216.157s-68.317 22.404-88.964 15.534C308.39 225.119 373.67 206 373.67 206L418 216.157z"
          fill="#FFC78B"
        />
        <path
          d="M418 217.355s-62.791 17.305-83.22 15.515c-20.43-1.79 31.245-23.87 31.245-23.87L418 217.355z"
          fill="url(#paint3_radial)"
        />
        <path
          d="M407.844 252.495c2.714-2.716 4.825-6.64 2.412-9.658-6.634-.603-12.967-1.508-19.602-2.112 4.524 2.414 6.333 7.846 4.222 11.77-2.412 4.828-8.745 7.544-14.777 9.657-16.284 6.035-34.378 9.959-52.472 11.769 3.317 4.225 1.206 10.261-3.016 13.883-4.222 3.621-9.951 5.432-15.681 6.941-33.776 9.657-62.123 13.882-97.406 7.242-9.047 10.865-27.744 13.581-41.315 6.64-5.428-3.018-10.253-6.941-15.681-9.959-5.429-3.018-12.365-4.829-17.793-2.716 2.111-6.64-8.142-12.373-16.887-13.279-8.444-.905-18.094 0-25.03-4.527-5.127-3.319-7.54-9.053-13.571-10.562-4.523-1.207-9.65.302-14.174-.604-9.047-1.81-12.062-10.26-18.395-15.089-6.333-5.13-17.19-7.545-19.3-13.279-.905-2.716.301-5.733-.302-8.45-.603-1.81-1.81-3.621-3.317-5.13-5.73-6.337-10.857-12.373-15.682-17.805-.301-.604-.301-1.207 0-1.811-1.206.302-2.412.604-3.317.905-2.412.604-4.825 1.509-6.031 2.716-2.111 2.717 3.92 3.32 6.936 4.527 6.634 2.716 3.619 11.166 11.76 13.581 1.81.603 3.921.603 5.127 1.81 1.508 1.509.604 3.622-.301 5.432-2.413 6.338 2.412 13.883 11.46 17.504 6.332 2.716 14.776 3.32 18.998 8.148 2.714 3.018 3.016 7.545 7.54 9.657 4.824 2.415 11.459 0 16.284 2.716 3.619 1.811 4.523 5.433 7.237 7.847 4.524 4.527 12.666 5.432 19.904 5.13 6.936-.302 14.475-1.509 21.11.906 6.634 2.414 10.856 10.864 5.126 14.184 6.635 0 12.968-.302 19.602-.604-.603 5.734 4.523 11.77 10.856 14.787 6.333 3.018 13.872 4.225 20.808 4.829 7.238.905 14.777 1.509 21.412-.604 6.634-2.112 12.364-7.544 11.459-13.882 6.032.302 9.952 6.338 15.38 8.45 3.016 1.207 6.635 1.207 9.952.906 34.077-2.113 66.646-9.054 99.818-18.711.604-7.545-3.317-15.391-10.554-20.521 27.141 4.225 57.599-3.018 77.201-22.634z"
          fill="#FFBE78"
        />
        <path
          d="M83.961 153.509c-7.84 3.018-18.697 8.752-19.602 14.787-.301 1.509.302 2.717-.904 4.527-1.508 2.113-4.825 3.32-7.54 4.225-5.73 2.113-12.062 5.734-14.776 9.657-3.016 3.924-.603 8.45 5.127 7.545-12.968 4.225-26.237 8.148-39.506 11.468-1.206.302-2.412.603-3.317.905 3.92 2.415 7.84 4.829 12.063 7.545.301.302.603.302 1.206.603-.603-1.508 0-3.319 1.508-4.828 3.015-2.414 7.539-3.923 11.76-4.829 4.223-.905 8.746-1.509 12.968-3.319 4.222-1.509 8.142-4.829 8.142-7.545 0-1.811-1.206-3.018-2.11-4.527-1.207-2.112-.604-5.13 2.412-7.846 4.222-3.622 11.761-4.829 15.078-9.054 1.207-1.509 1.207-2.716 1.81-4.225 2.11-5.432 9.047-10.864 15.681-14.787l3.62-1.811c-.906.604-2.112.905-3.62 1.509zM387.337 239.518c.905 0 1.81.302 2.714.905 7.238-6.941 16.285-12.373 26.84-15.089 8.142-2.113 17.792-3.32 20.205-9.054 1.508-3.923-1.508-8.751-6.333-12.976.905 3.319.301 6.941-1.508 9.657-4.222 5.734-12.666 9.053-20.808 12.373-8.444 3.32-16.888 7.243-21.11 14.184z"
          fill="#FFC78B"
        />
        <path
          d="M190.803 407c-14.383 1.807-28.474 5.119-40.803 9.635 0 .301 0 .301.294.603.88-.603 2.054-1.205 3.229-1.506 12.035 23.787 24.071 48.177 32.877 73.47 6.752 3.011 20.549 24.389 27.007 26.798 2.055-2.409-2.055-3.312 0-5.42.88-.903-7.339-9.635-6.752-10.84.881-2.107.294-4.817-.293-7.527-6.458-27.702-8.513-56.909-15.559-85.213z"
          fill="#E07500"
        />
        <path
          d="M276 473a2 2 0 11-.001-3.999A2 2 0 01276 473zM212 537a2 2 0 11-.001-3.999A2 2 0 01212 537zM188 505a2 2 0 11-.001-3.999A2 2 0 01188 505zM220 548a2 2 0 11-.001-3.999A2 2 0 01220 548z"
          fill="#FF9726"
        />
        <path
          d="M64.644 307.445c-1.22-3.002-2.44-6.305-3.66-9.307-6.709 0-13.112 1.201-18.906 2.702 3.05 9.306 6.403 79.253 10.673 89.46.61 1.201.915 2.401 1.525 3.602 2.744-3.302 6.098-67.545 9.148-71.147 1.524-1.801 3.05-3.603 3.354-5.704.61-3.002-.915-6.304-2.135-9.606zM83.245 371.687c-.915-.3-1.83-.9-2.44-.9-.61 0-1.22.3-1.525.9-5.184 5.104-9.758 30.621-14.027 35.424 6.709 4.503 16.772 31.221 20.431 37.225 4.27-22.215 3.355-45.03 7.319-67.245 25.31 13.509 17.991 9.606-9.758-5.404z"
          fill="#E07500"
        />
        <path
          d="M47.028 290.1c-3.483-1.8-7.257-3.6-10.74-5.1-6.096 1.2-12.482 2.1-18.288 3 6.677 6.3 15.965 12 25.835 13.5.29.6.29.9.58 1.5.29-.3.581-.6.871-1.2l4.355-5.4c.87-1.2 2.032-2.7.87-4.2-.87-.9-2.032-1.5-3.483-2.1zM382.684 316.304c-1.579 6.997-4.105 79.395-5.684 86.696 3.474-.913 6.632-2.129 10.105-2.738 2.211-6.388 5.369-77.874 7.895-84.262-4.105.608-8.211.913-12.316.304zM277 380c7.143-8.815 10.571-52.891 17.429-62 1.142 8.227 1.428 52.303 2.571 60.531L277 380z"
          fill="#E07500"
        />
        <path
          d="M334.655 282c.296 3.197 0 6.103-.886 9.299-.295 1.453-.886 2.906-2.068 3.778-.886.872-2.363 1.163-3.544 1.744a452.994 452.994 0 01-78.572 15.693c-3.545.29-7.09.581-10.634 0-5.613-.872-10.634-3.197-15.951-4.65 3.249 2.034 5.908 4.94 9.452 6.103 2.954 1.162 6.499 1.162 9.748.872 33.378-2.035 65.28-8.719 97.772-18.018.295-4.94-1.772-10.462-5.317-14.821z"
          fill="#FFD19F"
        />
        <path
          d="M211.278 316.343c.605-.574 2.42-1.435 2.722-2.295-2.42 2.295-7.561 3.442-11.493 3.155-3.931 0-7.561.287-11.492 0-9.376-.574-19.659-2.295-26.01-4.589-1.21-.287-2.42-1.722-3.327-2.295-.907-.287-1.512-1.148-2.419-1.721-2.42-2.295-4.839-4.59-7.259-6.598.302 5.163 5.141 10.04 10.888 12.622 6.351 2.868 13.912 4.015 20.868 4.589 7.259.861 14.82 1.434 21.473-.574 2.42-.286 4.234-1.147 6.049-2.294z"
          fill="#FAB69D"
        />
        <path
          d="M211.278 316.343c.605-.574 2.42-1.435 2.722-2.295-2.42 2.295-7.561 3.442-11.493 3.155-3.931 0-7.561.287-11.492 0-9.376-.574-19.659-2.295-26.01-4.589-1.21-.287-2.42-1.722-3.327-2.295-.907-.287-1.512-1.148-2.419-1.721-2.42-2.295-4.839-4.59-7.259-6.598.302 5.163 5.141 10.04 10.888 12.622 6.351 2.868 13.912 4.015 20.868 4.589 7.259.861 14.82 1.434 21.473-.574 2.42-.286 4.234-1.147 6.049-2.294z"
          fill="#FFC78B"
        />
        <path
          d="M130.062 303c.313-.846.625-1.692.938-2.256-8.129-.282-16.571-1.693-24.387-3.667-1.564-.564-3.127-.846-4.377-1.974-1.251-.847-1.876-1.975-2.189-3.103-.313 2.538.938 5.077 3.752 7.333 8.754.564 17.509 1.975 26.263 3.667z"
          fill="#FFDAB3"
        />
        <path
          d="M206 114.633c3.047-.606 5.79-.909 8.837-.303 5.789 1.213 10.97 4.849 17.064 6.061 7.618 1.516 14.931-1.212 22.854-.606 10.36.909 21.025 7.88 31.995 10.91 14.322 3.94 29.253 2.122 43.88 12.122 3.961 2.728 7.922 6.364 12.189 7.273 3.047.91 5.484.304 8.227.607 6.704.909 14.626 6.364 19.807 12.425 5.18 5.758 8.227 12.122 11.274 18.183.914 1.818 1.828 3.636 3.352 5.152 3.657 3.636 9.142 4.545 14.017 5.455 8.532 1.515 17.369 3.636 25.596 7.879 8.228 4.243 14.627 11.516 12.494 16.971-.305.606-.61.909-.914 1.515-3.352.909-7.314 1.212-10.665-.606 3.047-6.061-5.181-16.062-14.627-20.304-10.36-4.546-20.111-4.243-29.253-3.637-10.36-9.698-12.189-24.547-23.768-32.73-8.227-5.758-16.455-4.849-24.987-8.485-6.399-2.728-12.188-7.879-18.283-10.607-6.704-3.03-12.798-2.424-19.197-3.333-13.408-2.122-26.815-11.516-39.309-15.153-10.055-2.728-19.806-1.818-29.557-2.728-7.009 0-14.932-1.515-21.026-6.061z"
          fill="#FFC78B"
        />
        <path
          d="M235 317.217c-7.937 21-15.874 82.783-24.084 103.479-.821 0-1.095.304-1.916.304 6.568-21.304 13.137-83.696 19.705-105 2.463.609 3.832.913 6.295 1.217zM365 275c-3.226 15.419-5.161 23.279-10 39 2.258-15.419 2.903-22.977 3.871-38.093 2.581-.302 3.548-.605 6.129-.907z"
          fill="#FFB15B"
        />
        <path
          d="M417.409 216.133c-3.249-14.182-10.338-27.459-20.381-37.719-.591-.603-1.477-1.509-2.363-1.81-1.477-.302-2.954.603-4.431 1.81-6.794 5.432-13.292 11.467-19.495 17.804-2.658 2.414-5.022 5.129-6.203 8.75-1.182 3.621-1.182 7.243-.886 11.165 17.132 3.621 34.855 3.621 51.987.604l1.772-.604z"
          fill="#E07500"
        />
        <path
          d="M385.213 218.547c-1.477-3.017-2.068-6.638-2.659-10.259-.886-7.846-1.181-16.295.886-24.141 2.363-1.81 4.431-3.922 6.794-5.733 1.182-.905 2.659-2.112 4.431-1.81.886.301 1.772.905 2.363 1.81 10.043 10.26 17.132 23.537 20.381 37.719l-1.772.302c-9.748 1.811-20.086 2.414-30.424 2.112z"
          fill="#FF9726"
        />
        <path
          d="M388.757 203.46c.886 0 1.772 0 2.659.301 6.498 1.207 13.587.604 19.79-1.81 0-3.319-.886-6.639-2.363-9.656-.295-.905-1.182-1.811-2.068-1.811-.59 0-1.181.302-1.477.604-5.021 3.319-10.043 6.94-14.769 10.863l-1.772 1.509z"
          fill="#FF9726"
        />
        <path
          d="M382.259 199.839c-.295-5.432.296-10.864 1.477-15.692 2.363-1.81 4.431-3.922 6.794-5.733 1.182-.905 2.658-2.112 4.431-1.81.886.301 1.772.905 2.363 1.81a75.433 75.433 0 0110.338 13.277c-.886 1.811-2.068 3.621-3.249 5.13-2.659 2.716-6.203 4.526-10.043 5.733-2.068.604-4.431.906-6.498.604-2.363-.302-4.136-1.509-5.613-3.319z"
          fill="#FFC78B"
        />
        <path
          d="M364.831 204.667c1.182-3.32 3.84-6.035 6.203-8.751 2.954-3.018 5.908-5.734 8.862-8.751-1.773 6.035-1.773 12.674-1.773 19.01 0 .906 0 1.811-.591 2.716-.886 1.811-3.544 1.509-5.316.906-2.954-.906-5.908-1.811-8.271-3.622.295-.301.591-.905.886-1.508z"
          fill="#FF9726"
        />
        <path
          d="M381.98 201.059c-.149-1.625 1.076-3.065 2.736-3.216 1.66-.152 3.126 1.042 3.274 2.667.148 1.624-1.077 3.064-2.736 3.216-1.66.152-3.126-1.042-3.274-2.667z"
          fill="#FCD7CD"
        />
        <path
          d="M375.4 223.205c-1.8-7.756-6-15.224-12-20.969-.3-.287-.9-.861-1.5-.861-.9-.287-1.8.287-2.7.861-3.9 3.16-7.8 6.32-11.7 9.767-1.5 1.436-3 2.872-3.6 4.883-.9 2.01-.6 4.021-.6 6.319 10.2 2.01 20.7 2.01 30.9.287l1.2-.287z"
          fill="#E07500"
        />
        <path
          d="M356.5 224.641c-.9-1.723-1.2-3.734-1.5-5.745-.6-4.308-.6-9.191.6-13.5 1.2-1.149 2.7-2.298 3.9-3.16.9-.574 1.8-1.148 2.7-.861.6 0 .9.574 1.5.861 6 5.745 10.2 13.213 12 20.969h-.9c-6.3 1.149-12.3 1.723-18.3 1.436z"
          fill="#FF9726"
        />
        <path
          d="M358.3 216.311c.6 0 1.2 0 1.5.287 3.9.862 8.1.288 11.7-.861 0-2.011-.6-3.735-1.5-5.458-.3-.574-.6-.862-1.2-.862-.3 0-.6.288-.9.288l-9 6.032-.6.574z"
          fill="#FF9726"
        />
        <path
          d="M354.4 214.3c0-2.872 0-6.032.9-8.904 1.2-1.149 2.7-2.298 3.9-3.16.9-.574 1.8-1.148 2.7-.861.6 0 .9.574 1.5.861 2.4 2.298 4.5 4.883 6 7.469-.6 1.149-1.2 2.01-2.1 2.872-1.5 1.436-3.6 2.585-6 3.16-1.2.287-2.7.574-3.9.287-.9 0-2.1-.862-3-1.724z"
          fill="#FFC78B"
        />
        <path
          d="M344.2 216.886c.6-2.011 2.1-3.447 3.6-4.883 1.8-1.724 3.6-3.16 5.4-4.883-.9 3.446-1.2 7.18-.9 10.627 0 .575 0 1.149-.3 1.436-.6.862-2.1.862-3.3.575-1.8-.287-3.6-.862-4.8-2.011 0 0 0-.574.3-.861z"
          fill="#FF9726"
        />
        <path
          d="M354.311 214.945c-.091-.99.605-1.863 1.552-1.949.948-.087 1.79.646 1.881 1.636.09.99-.605 1.862-1.553 1.949-.948.087-1.79-.646-1.88-1.636z"
          fill="#FCD7CD"
        />
        <path
          d="M198 314.127s-20.718 7.412-22.79 6.842c-2.072-.285-18.351-2.566-20.127-8.267C153.307 307 180.537 307 180.537 307L198 314.127z"
          fill="url(#paint4_radial)"
        />
        <path
          d="M202.4 310.277c-1.8-7.756-6-15.224-12-20.969-.3-.287-.9-.861-1.5-.861-.9-.288-1.8.287-2.7.861-3.9 3.16-7.8 6.319-11.7 9.766-1.5 1.436-3 2.873-3.6 4.883-.9 2.011-.6 4.022-.6 6.32 10.2 2.01 20.7 2.01 30.9.287l1.2-.287z"
          fill="#E07500"
        />
        <path
          d="M183.5 311.425c-.9-1.723-1.2-3.734-1.5-5.744-.6-4.309-.6-9.192.6-13.5 1.2-1.149 2.7-2.298 3.9-3.16.9-.574 1.8-1.149 2.7-.862.6 0 .9.575 1.5.862 6 5.745 10.2 13.213 12 20.968h-.9c-6.3 1.436-12.3 1.724-18.3 1.436z"
          fill="#FF9726"
        />
        <path
          d="M185.3 303.096c.6 0 1.2 0 1.5.287 3.9.862 8.1.287 11.7-.862 0-2.011-.6-3.734-1.5-5.457-.3-.575-.6-.862-1.2-.862-.3 0-.6.287-.9.287l-9 6.032-.6.575z"
          fill="#FF9726"
        />
        <path
          d="M181.4 301.085c0-2.872 0-6.032.9-8.904 1.2-1.149 2.7-2.298 3.9-3.16.9-.574 1.8-1.149 2.7-.862.6 0 .9.575 1.5.862 2.4 2.298 4.5 4.883 6 7.468-.6 1.149-1.2 2.011-2.1 2.873-1.5 1.436-3.6 2.585-6 3.159-1.2.287-2.7.575-3.9.287-.9 0-2.1-.861-3-1.723z"
          fill="#FFC78B"
        />
        <path
          d="M171.2 303.67c.6-2.011 2.1-3.447 3.6-4.883 1.8-1.723 3.6-3.16 5.4-4.883-.9 3.447-1.2 7.181-.9 10.628 0 .574 0 1.149-.3 1.436-.6.862-2.1.862-3.3.574-1.8-.287-3.6-.861-4.8-2.01 0 0 0-.287.3-.862z"
          fill="#FF9726"
        />
        <path
          d="M181.334 301.808c-.09-.99.605-1.862 1.553-1.949.948-.087 1.79.646 1.88 1.636.09.99-.605 1.863-1.553 1.949-.948.087-1.789-.646-1.88-1.636z"
          fill="#FCD7CD"
        />
        <path
          d="M52 229c-22.644 0-41-5.373-41-12s18.356-12 41-12 41 5.373 41 12-18.356 12-41 12z"
          fill="#FFC78B"
        />
        <path
          d="M53.5 228c-21.815 0-39.5-5.149-39.5-11.5S31.685 205 53.5 205 93 210.149 93 216.5 75.315 228 53.5 228z"
          fill="url(#paint5_radial)"
        />
        <path
          opacity=".19"
          d="M97.846 198.87L64.59 179.895l39.41 16.59-6.154 2.385z"
          fill="url(#paint6_linear)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M93.435 184.033c.1 0 0-.106 0 0 0-.106 0 0 0 0z"
          fill="#9F0"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M93.434 183.927s.1.106 0 0c0 .635.2 1.164.502 1.693.2.424.502.741.803.953v.317c.1.212.1 1.27.1 3.387v6.561c0 .317 0 .529.2.635.402.846 1.205 1.481 2.309 2.01.502.318 1.104.424 1.605.424h.602c-.2-.635-.702-1.059-1.505-1.482-.803-.423-1.304-.741-1.505-1.164-.2-.318-.2-.635-.1-.952h.501c-.401-.106-.602-.212-.602-.53v-9.312c.1-.212.201-.529.201-.847 0-.317 0-.529-.1-.846H95.04c-.402-8.572.3-12.911 1.906-13.123.703-.317 1.506-.74 2.208-1.481 0-.106.1-.106.1-.212-.903-.635-1.404-1.587-1.605-2.857-.2-1.27-.2-1.905 0-2.117 0-.317.2-.529.401-.635h.201c.301-.105.903 0 1.806 0 1.104.106 2.108-.211 3.011-1.058.502-.423.803-.846.903-1.164.201-.423.201-.741 0-1.058a4.54 4.54 0 00-.602-.741c-.502-.529-1.004-.846-1.606-1.164-.1 0-.2-.106-.301-.106a4.352 4.352 0 00-1.605-.317c-.703 0-1.305.106-1.907.423-.602.212-1.104.635-1.606 1.164-.903.953-1.405 2.011-1.505 3.175v1.164c0 .529.1.952.2 1.481.201.635.402 1.165.803 1.694l.502.529.502.529-.502.317c-1.906 1.27-2.91 3.598-3.11 7.196v7.091c.2.317.2.317.2.423zm12.945 12.487c0-.74-.602-1.058-1.906-1.164-1.706-.529-2.71-.846-2.91-1.058-.201-.212-.302-.423-.402-.529l-.201-6.561c.201-.953.402-1.693.603-2.328l-.101-.424a3.819 3.819 0 00-1.505 1.376v9.101c.903.423 1.907.952 3.111 1.376h.1c1.405.423 2.409.423 3.211.211zM98.05 184.35c.502 0 1.004 0 1.505-.105l-.1-8.043c-.1-.635-.401-1.058-1.104-1.27v8.889c0 .212-.1.318-.2.318l-.1.211z"
          fill="#fff"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M99.355 170.17h.803c.903 0 1.606-.212 2.308-.635.1 0 .201-.106.301-.106l.201-.212c.1 0 .1-.105.201-.105l.1-.106s.1 0 .1-.106l.101-.106.1-.106.201-.317.2-.106c.402-.529.703-1.164.803-1.799.101-.423.201-.952.201-1.482v-1.164c0-.423-.1-.74-.201-1.164-.1-.211-.1-.423-.2-.635 0-.106-.101-.211-.101-.211-.1-.106-.201-.212-.201-.318.101.318.101.741 0 1.058-.2.424-.501.847-.903 1.165-.903.846-1.906 1.164-3.01 1.058-.903-.106-1.505-.106-1.807 0h-.2c-.301.106-.402.317-.402.635-.2.106-.2.846 0 2.116.1 1.058.602 2.011 1.405 2.646zm4.917-5.926c.101.423.201.846.101 1.164 0 1.164-.502 1.905-1.305 2.222l-.301-.212c-.1-.423-.1-.846-.1-1.164 0-1.27.602-2.116 1.605-2.434-.1.106 0 .318 0 .424z"
          fill="#666"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M104.272 164.244c0-.106-.1-.212-.1-.424-1.003.318-1.505 1.059-1.606 2.434 0 .424 0 .847.101 1.164l.301.212c.903-.317 1.304-1.058 1.304-2.222.201-.424.101-.741 0-1.164z"
          fill="#999"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M99.957 185.726c.401-.635.903-1.164 1.505-1.376l.1.424c-.301.635-.501 1.481-.602 2.328l.201 6.561c0 .106.1.318.401.529.201.212 1.205.529 2.911 1.058 1.304.106 1.906.424 1.906 1.164.201-.105.402-.105.502-.211.301-.212.502-.635.703-1.058 0-.318-.201-.741-.502-1.059-.502-.529-1.305-1.058-2.509-1.481v-8.36c0-.741.1-1.482.401-2.223.301.106.603.106.904-.106.301-.211.501-.634.501-1.164 0-.105 0-.317-.1-.635l.1-.105c.201-.212.201-.318.201-.53v-5.079c0-.212 0-.423-.1-.741v-.106a8.601 8.601 0 00-.502-1.905c-.301-.846-.803-1.481-1.405-1.904-.201-.318-.602-.635-1.003-.953l-.101.106-.1.106s-.101 0-.101.106l-.1.105c-.1 0-.1.106-.201.106-.1 0-.1.106-.2.212-.101 0-.201.106-.301.106a4.481 4.481 0 01-2.308.635h-.803c0 .106 0 .106-.1.211-.703.741-1.405 1.27-2.208 1.482-1.606.212-2.308 4.55-1.907 13.122h1.405c.1.318.1.529.1.847 0 .317-.1.635-.2.846v9.313c0 .212.2.423.602.529h-.502c-.1.318-.1.635.1.953.201.317.703.74 1.505 1.164.803.423 1.305.952 1.506 1.481 1.003-.106 1.405-.423 1.405-1.164.1-.846-.301-1.693-1.205-2.646l-.501-.529v-8.148c.1-.318.1-.529.2-.741.201-.741.301-.952.402-1.27zm-1.907-1.376s0-.105.1-.105c.1-.106.1-.212.201-.318v-8.995c.703.212 1.104.635 1.104 1.27l.1 8.043c-.501.105-1.003.105-1.505.105z"
          fill="#AEAEAE"
        />
        <path
          opacity=".19"
          d="M73.846 198.87L40.59 179.895 80 196.485l-6.154 2.385z"
          fill="url(#paint7_linear)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M69.435 184.033c.1 0 0-.106 0 0 0-.106 0 0 0 0z"
          fill="#9F0"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M69.435 183.927s.1.106 0 0c0 .635.2 1.164.502 1.693.2.424.502.741.803.953v.317c.1.212.1 1.27.1 3.387v6.561c0 .317 0 .529.2.635.402.846 1.205 1.481 2.309 2.01.501.318 1.104.424 1.605.424h.603c-.201-.635-.703-1.059-1.506-1.482-.803-.423-1.304-.741-1.505-1.164-.2-.318-.2-.635-.1-.952h.501c-.401-.106-.602-.212-.602-.53v-9.312c.1-.212.201-.529.201-.847 0-.317 0-.529-.1-.846H71.04c-.402-8.572.3-12.911 1.906-13.123.703-.317 1.506-.74 2.208-1.481 0-.106.1-.106.1-.212-.903-.635-1.404-1.587-1.605-2.857-.2-1.27-.2-1.905 0-2.117 0-.317.2-.529.401-.635h.2c.302-.105.904 0 1.807 0 1.104.106 2.108-.211 3.01-1.058.502-.423.804-.846.904-1.164.2-.423.2-.741 0-1.058-.2-.318-.401-.529-.602-.741-.502-.529-1.004-.846-1.606-1.164-.1 0-.2-.106-.3-.106a4.35 4.35 0 00-1.606-.317c-.703 0-1.305.106-1.907.423-.602.212-1.104.635-1.606 1.164-.903.953-1.405 2.011-1.505 3.175v1.164c0 .529.1.952.2 1.481.201.635.402 1.165.803 1.694l.502.529.502.529-.502.317c-1.906 1.27-2.91 3.598-3.11 7.196v7.091c.2.317.2.317.2.423zm12.945 12.487c0-.74-.602-1.058-1.906-1.164-1.706-.529-2.71-.846-2.91-1.058-.201-.212-.301-.423-.402-.529l-.2-6.561c.2-.953.4-1.693.602-2.328l-.1-.424a3.82 3.82 0 00-1.506 1.376v9.101c.903.423 1.907.952 3.11 1.376h.101c1.405.423 2.409.423 3.211.211zm-8.329-12.064c.502 0 1.004 0 1.505-.105l-.1-8.043c-.1-.635-.401-1.058-1.104-1.27v8.889c0 .212-.1.318-.2.318l-.1.211z"
          fill="#fff"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M75.355 170.17h.803c.903 0 1.606-.212 2.308-.635.1 0 .2-.106.301-.106l.2-.212c.101 0 .101-.105.202-.105l.1-.106s.1 0 .1-.106l.1-.106.101-.106.2-.317.201-.106c.402-.529.703-1.164.803-1.799.1-.423.2-.952.2-1.482v-1.164c0-.423-.1-.74-.2-1.164-.1-.211-.1-.423-.2-.635 0-.106-.1-.211-.1-.211-.101-.106-.202-.212-.202-.318.1.318.1.741 0 1.058-.2.424-.501.847-.903 1.165-.903.846-1.906 1.164-3.01 1.058-.903-.106-1.505-.106-1.807 0h-.2c-.301.106-.402.317-.402.635-.2.106-.2.846 0 2.116.1 1.058.602 2.011 1.405 2.646zm4.917-5.926c.1.423.201.846.1 1.164 0 1.164-.501 1.905-1.304 2.222l-.3-.212c-.101-.423-.101-.846-.101-1.164 0-1.27.602-2.116 1.605-2.434-.1.106 0 .318 0 .424z"
          fill="#666"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M80.272 164.244c0-.106-.1-.212-.1-.424-1.004.318-1.505 1.059-1.606 2.434 0 .424 0 .847.1 1.164l.302.212c.903-.317 1.304-1.058 1.304-2.222.201-.424.1-.741 0-1.164z"
          fill="#999"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M75.958 185.726c.401-.635.903-1.164 1.505-1.376l.1.424c-.3.635-.501 1.481-.602 2.328l.201 6.561c0 .106.1.317.401.529.201.212 1.205.529 2.91 1.058 1.305.106 1.907.424 1.907 1.164.201-.105.402-.105.502-.211.301-.212.502-.635.703-1.059 0-.317-.201-.74-.502-1.058-.502-.529-1.305-1.058-2.509-1.481v-8.36c0-.741.1-1.482.401-2.223.301.106.603.106.904-.106.3-.211.501-.635.501-1.164 0-.106 0-.317-.1-.635l.1-.105c.201-.212.201-.318.201-.53v-5.079c0-.212 0-.424-.1-.741v-.106a8.556 8.556 0 00-.502-1.905c-.301-.846-.803-1.481-1.405-1.905-.2-.317-.602-.634-1.004-.952l-.1.106-.1.106s-.1 0-.1.106l-.1.105c-.101 0-.101.106-.202.106-.1 0-.1.106-.2.212-.1 0-.201.106-.301.106a4.478 4.478 0 01-2.308.635h-.803c0 .105 0 .105-.1.211-.703.741-1.406 1.27-2.208 1.482-1.606.211-2.308 4.55-1.907 13.122h1.405c.1.318.1.529.1.847 0 .317-.1.635-.2.846v9.313c0 .212.2.423.602.529h-.502c-.1.317-.1.635.1.952.201.318.703.741 1.505 1.165.803.423 1.305.952 1.506 1.481 1.003-.106 1.405-.423 1.405-1.164.1-.847-.301-1.693-1.204-2.646l-.502-.529v-8.148c.1-.318.1-.529.2-.741.201-.741.302-.952.402-1.27zm-1.907-1.376s0-.105.1-.105c.1-.106.1-.212.201-.318v-8.995c.703.212 1.104.635 1.104 1.27l.1 8.043c-.501.105-1.003.105-1.505.105z"
          fill="#AEAEAE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M193.536 279.661l7.567 4.384c3.318 1.922 7.517 2.953 11.564 3.052 3.755.093 7.379-.614 10.044-2.161l12.202-7.084 15.942 9.245 7.312 4.241a11.838 11.838 0 0011.883 0l48.366-28.049a9.216 9.216 0 004.583-7.974v-23.484a5.857 5.857 0 00-1.153-3.629l-13.293-15.895v-39.445l-84.333-51.799c-6.397-3.706-16.07-4.105-21.607-.891l-83.88 48.692c-2.644 1.535-3.863 3.616-3.722 5.776.012.187.038.376.071.564a5.052 5.052 0 00-.071 1.235v53.431a4.971 4.971 0 000 .598c.109 1.677 1.042 3.399 2.766 4.95l75.759 44.243z"
          fill="#000"
          fill-opacity=".2"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M193.536 274.661l7.567 4.384c3.318 1.922 7.517 2.953 11.564 3.052 3.755.093 7.379-.614 10.044-2.161l12.202-7.084 15.942 9.245 7.312 4.241a11.838 11.838 0 0011.883 0l48.366-28.049a9.216 9.216 0 004.583-7.974v-23.484a5.857 5.857 0 00-1.153-3.629l-13.293-15.895v-39.445l-84.333-51.799c-6.397-3.706-16.07-4.105-21.607-.891l-83.88 48.692c-2.644 1.535-3.863 3.616-3.722 5.776.012.187.038.376.071.564a5.052 5.052 0 00-.071 1.235v53.431a4.971 4.971 0 000 .598c.109 1.677 1.042 3.399 2.766 4.95l75.759 44.243z"
          fill="#CDCDCD"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M310.166 170.346c-.154-2.351-1.935-4.794-5.275-6.72l-80.753-46.579c-6.392-3.687-16.058-4.084-21.591-.886L118.73 164.6c-2.642 1.527-3.86 3.598-3.719 5.746.154 2.35 1.935 4.793 5.275 6.72l80.753 46.578c6.391 3.687 16.058 4.084 21.591.886l83.816-48.439c2.642-1.527 3.86-3.598 3.72-5.745z"
          fill="#fff"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M202.385 114.167l-83.662 48.573c-2.637 1.531-3.853 3.607-3.712 5.761.154 2.356 1.931 4.806 5.265 6.738l80.603 46.707c6.38 3.697 16.029 4.095 21.551.888l83.662-48.572c2.637-1.531 3.853-3.608 3.713-5.761-.154-2.357-1.932-4.808-5.265-6.738l-80.604-46.707c-3.486-2.02-7.95-3.055-12.182-3.056-3.512 0-6.864.713-9.369 2.167"
          fill="url(#paint8_linear)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M257.207 254.155L235 224.389l.386-1.571L295.746 188l26.029 34.623c2.048 2.746 1.296 6.393-1.672 8.105l-46.476 26.809a12.62 12.62 0 01-16.42-3.382"
          fill="#fff"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M257.859 254.165L235 223.526 296.588 188l25.267 33.868a5.8 5.8 0 01-1.752 8.492l-46.789 26.989a11.878 11.878 0 01-15.455-3.184"
          fill="#fff"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M250.927 279.785v-33.436L235 225v45.597l15.927 9.188z"
          fill="#B8B8B8"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M295.669 206.645c-.273 0-.548.07-.799.214l-38.425 22.165a1.597 1.597 0 00-.482 2.338l9.823 13.166a1.599 1.599 0 002.08.428l38.425-22.165a1.599 1.599 0 00.482-2.337l-9.823-13.166a1.592 1.592 0 00-1.281-.643zm-28.601 39.171a2.245 2.245 0 01-1.801-.903l-9.822-13.165a2.243 2.243 0 01.677-3.283l38.425-22.164a2.245 2.245 0 012.922.601l9.822 13.166a2.242 2.242 0 01-.677 3.282l-38.425 22.165a2.238 2.238 0 01-1.121.301"
          fill="#B7CCDA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M275.464 170.595c-.099-1.527-1.244-3.113-3.391-4.364l-51.912-30.252c-4.11-2.394-10.324-2.652-13.881-.575l-53.882 31.46c-1.524.89-2.31 2.064-2.391 3.303-.027.412.024.831.152 1.248l.007.02c.029.093.062.187.099.281l.019.046a4.464 4.464 0 00.279.563l.03.053c.055.09.113.181.174.271l.018.027c.138.199.296.397.471.592l.051.055a7.474 7.474 0 00.622.599c.066.058.135.114.205.171l.128.102c.072.056.146.111.221.166l.134.098c.083.058.169.115.256.173.041.027.08.055.123.082.129.082.261.164.398.244l.004.002 51.912 30.252c2.312 1.347 5.289 2.018 8.085 1.977.59-.008 1.17-.051 1.736-.123l.216-.03c1.437-.206 2.764-.618 3.844-1.249l34.148-19.938 19.734-11.522.009-.005a6.89 6.89 0 00.305-.19c.037-.024.071-.049.106-.073.063-.042.126-.085.186-.129.042-.031.082-.063.123-.095.049-.038.099-.076.146-.114.043-.036.084-.073.125-.109.041-.036.082-.071.121-.108.043-.04.083-.081.123-.121.034-.034.069-.067.101-.101.041-.044.08-.089.119-.133.027-.032.056-.063.082-.095.039-.048.076-.096.113-.145.022-.029.045-.058.066-.088.037-.052.072-.104.106-.156l.052-.081a3.567 3.567 0 00.222-.42l.029-.065a5 5 0 00.074-.191l.019-.056c.022-.067.043-.135.061-.203l.012-.047c.018-.071.033-.143.046-.215l.006-.035c.013-.076.023-.152.031-.227l.002-.026a3.16 3.16 0 00.012-.239v-.013a3.532 3.532 0 00-.006-.252"
          fill="#DC7300"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M275.968-42.133A1.865 1.865 0 00274.105-44H151.869a1.865 1.865 0 00-1.862 1.867v211.386c-.027.41.024.826.153 1.243.161.431.199.515.241.6.249.449.308.539.369.629.157.226.315.422.491.617.297.307.329.337.36.366.383.342.451.399.521.455.569.423.655.48.742.537.253.164.386.245.524.325l52.121 30.14c2.32 1.342 5.309 2.01 8.116 1.97a15.542 15.542 0 001.743-.123c1.659-.235 2.991-.645 4.075-1.273l34.283-19.864 19.812-11.479c.353-.217.386-.242.422-.266.359-.262.409-.299.456-.338.29-.255.331-.296.371-.336a7.78 7.78 0 00.417-.472c.259-.379.29-.435.321-.491.387-1.135.398-1.211.405-1.287.011-.24.016-71.02.018-212.339z"
          fill="url(#paint9_linear)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M249.648 226.673a.65.65 0 01-.326-1.21l45.249-26.375a.647.647 0 11.651 1.121l-45.25 26.376a.647.647 0 01-.324.088"
          fill="#fff"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M216.406 257.561v-25.952l-28.38-9.974-9.926-9.928L134.241 199 124 207.484v4.223l5.455 1.792.04-6.873 80.785 46.808v6.597l6.126-2.47z"
          fill="#CDCDCD"
        />
        <g opacity=".81">
          <path
            className={styles.numberTwelve}
            opacity=".372"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M191.267 173.83c0-1.903.307-3.305.921-4.207.615-.902 1.561-1.353 2.84-1.353 1.243 0 2.184.471 2.82 1.415.637.944.955 2.326.955 4.145 0 1.883-.308 3.277-.925 4.181-.617.905-1.567 1.357-2.85 1.357-1.244 0-2.182-.467-2.814-1.401-.632-.934-.947-2.313-.947-4.137zm2.61-2.847c-.231.576-.346 1.524-.346 2.847 0 1.322.114 2.27.342 2.843.229.572.614.859 1.155.859.531 0 .914-.29 1.15-.87.236-.58.354-1.524.354-2.832 0-1.323-.119-2.273-.358-2.85-.238-.578-.62-.867-1.146-.867-.536 0-.92.29-1.151.87z"
            fill="#fff"
          />
          <path
            className={styles.numberSix}
            opacity=".341"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M218.148 83.403c0-1.902.308-3.305.922-4.207.615-.902 1.561-1.353 2.839-1.353 1.244 0 2.184.472 2.821 1.416s.955 2.325.955 4.144c0 1.883-.308 3.277-.925 4.182-.617.904-1.567 1.357-2.851 1.357-1.243 0-2.181-.467-2.813-1.401-.632-.935-.948-2.314-.948-4.138zm2.611-2.846c-.231.575-.347 1.524-.347 2.846 0 1.323.115 2.27.343 2.843.229.573.614.86 1.154.86.531 0 .915-.29 1.151-.87.236-.581.354-1.525.354-2.833 0-1.322-.119-2.272-.358-2.85-.238-.578-.621-.866-1.147-.866-.535 0-.919.29-1.15.87z"
            fill="#fff"
          />
          <path
            className={styles.numberTwo}
            opacity=".506"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M171.148 29.403c0-1.902.308-3.305.922-4.207.615-.902 1.561-1.353 2.839-1.353 1.244 0 2.184.472 2.821 1.416s.955 2.325.955 4.144c0 1.883-.308 3.277-.925 4.182-.617.904-1.567 1.357-2.851 1.357-1.243 0-2.181-.467-2.813-1.401-.632-.935-.948-2.314-.948-4.138zm2.611-2.846c-.231.575-.347 1.524-.347 2.846 0 1.323.115 2.27.343 2.843.229.573.614.86 1.154.86.531 0 .915-.29 1.151-.87.236-.58.354-1.525.354-2.833 0-1.322-.119-2.272-.358-2.85-.238-.578-.621-.866-1.147-.866-.535 0-.919.29-1.15.87z"
            fill="#fff"
          />
          <path
            className={styles.numberThree}
            opacity=".513"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M237.737 40.227c0-1.903.308-3.305.922-4.207.615-.902 1.561-1.354 2.839-1.354 1.244 0 2.184.473 2.821 1.416.637.944.955 2.326.955 4.145 0 1.883-.308 3.277-.925 4.181-.617.905-1.568 1.357-2.851 1.357-1.244 0-2.181-.467-2.813-1.401-.632-.934-.948-2.313-.948-4.137zm2.611-2.847c-.231.575-.347 1.524-.347 2.847 0 1.322.115 2.27.343 2.843.229.572.613.859 1.154.859.531 0 .915-.29 1.151-.87.236-.58.354-1.524.354-2.832 0-1.323-.12-2.273-.358-2.85-.238-.578-.621-.867-1.147-.867-.536 0-.919.29-1.15.87z"
            fill="#fff"
          />
          <path
            className={styles.numberTen}
            opacity=".372"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M164.893 135.19l3.473-2.766h1.873v10.782h-2.279v-6.239l.022-1.025.037-1.121c-.378.379-.641.627-.789.745l-1.239.996-1.098-1.372z"
            fill="#fff"
          />
          <path
            className={styles.numberNine}
            opacity=".372"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M210.069 121.896l3.474-2.766h1.873v10.782h-2.279v-6.239l.022-1.025.037-1.121c-.378.379-.641.627-.789.745l-1.239.995-1.099-1.371z"
            fill="#fff"
          />
          <path
            className={styles.numberSeven}
            opacity=".219"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M175.48 86.72l3.474-2.766h1.873v10.781h-2.279v-6.239l.022-1.024.037-1.121c-.378.378-.641.626-.789.744l-1.239.996-1.099-1.372z"
            fill="#fff"
          />
          <path
            className={styles.numberFour}
            opacity=".463"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M198.069 51.543l3.474-2.766h1.873V59.56h-2.279V53.32l.022-1.025.037-1.12c-.378.378-.641.626-.789.744l-1.239.995-1.099-1.371z"
            fill="#fff"
          />
          <path
            className={styles.numberOne}
            opacity=".463"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M222.069 3.543l3.474-2.766h1.873V11.56h-2.279V5.32l.022-1.025.037-1.121c-.378.379-.641.627-.789.745l-1.239.995-1.099-1.371z"
            fill="#fff"
          />
          <path
            className={styles.numberFive}
            opacity=".372"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M247.378 70.131l3.473-2.765h1.873v10.781h-2.278v-6.239l.022-1.025.037-1.12a12.23 12.23 0 01-.789.744l-1.239.996-1.099-1.372z"
            fill="#fff"
          />
          <path
            className={styles.numberEight}
            opacity=".372"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M247.378 107.307l3.473-2.765h1.873v10.781h-2.278v-6.238l.022-1.025.037-1.121c-.379.378-.642.627-.789.745l-1.239.995-1.099-1.372z"
            fill="#fff"
          />
          <path
            className={styles.numberEleven}
            opacity=".372"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M238.084 144.484l3.473-2.765h1.873V152.5h-2.278v-6.239l.022-1.025.037-1.121c-.379.379-.642.627-.789.745l-1.239.996-1.099-1.372z"
            fill="#fff"
          />
          <path
            className={styles.numberThirteen}
            opacity=".372"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M219.495 172.366l3.473-2.765h1.874v10.781h-2.279v-6.238l.022-1.026.037-1.12c-.379.378-.642.626-.789.744l-1.239.996-1.099-1.372z"
            fill="#fff"
          />
        </g>
        <path
          d="M295.516 263.091c.022.318.252.452.515.299l4.503-2.627c.262-.153.457-.536.435-.854l-.097-1.392c-.022-.318-.253-.452-.515-.299l-4.503 2.628c-.263.153-.457.535-.435.853l.097 1.392z"
          fill="url(#paint10_linear)"
        />
        <path
          d="M292.566 266.902l-.823-11.829 9.669-5.642.823 11.829-9.669 5.642z"
          stroke="url(#paint11_linear)"
          stroke-width="1.78"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(180 139.93 179.147) scale(191.193)"
        >
          <stop stop-color="#CB7C27" />
          <stop offset=".893" stop-color="#FF9726" />
        </radialGradient>
        <radialGradient
          id="paint1_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-293.97 0 0 -293.972 390.855 297.097)"
        >
          <stop offset=".33" stop-color="#EF8007" />
          <stop offset="1" stop-color="#FFB360" />
        </radialGradient>
        <radialGradient
          id="paint2_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-62.7229 0 0 -62.7227 410.786 142.714)"
        >
          <stop stop-color="#FF8B0D" />
          <stop offset=".893" stop-color="#FFA646" />
        </radialGradient>
        <radialGradient
          id="paint3_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-46.9515 0 0 -46.9511 422.404 201.835)"
        >
          <stop stop-color="#B36E24" />
          <stop offset=".893" stop-color="#EB8619" />
        </radialGradient>
        <radialGradient
          id="paint4_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-34.1403 0 0 -38.1791 222.922 293.435)"
        >
          <stop stop-color="#D1720A" />
          <stop offset=".893" stop-color="#D97D18" />
        </radialGradient>
        <radialGradient
          id="paint5_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-62.7229 0 0 -62.7227 138.786 182.714)"
        >
          <stop stop-color="#FF8B0D" />
          <stop offset=".893" stop-color="#FFA646" />
        </radialGradient>
        <linearGradient
          id="paint6_linear"
          x1="64.636"
          y1="189.398"
          x2="103.984"
          y2="189.398"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-opacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="40.636"
          y1="189.398"
          x2="79.984"
          y2="189.398"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-opacity="0" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="115"
          y1="168.501"
          x2="309.816"
          y2="168.501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F6F7FF" />
          <stop offset="1" stop-color="#F2F2F9" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="212.984"
          y1="181.114"
          x2="212.984"
          y2="1.342"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF9726" />
          <stop offset="1" stop-color="#EE503B" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear"
          x1="293.444"
          y1="262.452"
          x2="297.692"
          y2="265.693"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF6B00" />
          <stop offset="1" stop-color="#FFC24D" />
        </linearGradient>
        <linearGradient
          id="paint11_linear"
          x1="286.981"
          y1="257.997"
          x2="303.926"
          y2="264.43"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5F92FB" />
          <stop offset="1" stop-color="#2056C6" />
        </linearGradient>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h438v548H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MaintenanceIllustration;
