import { baseUrl as apiUrl } from 'config';
import queryClient from 'clients/query';
import { parseRequestOptions } from 'utils/fetch';
import { HTTP_STATUS_CODES_TO_LOCK_UI, notifyHttpStatusError } from 'services/http';
import { STATUS_CODES } from 'utils/http';

const ERROR_MESSAGES = {
  UNKNOWN: `Une erreur s'est produite. Veuillez réessayer plus tard.`,
  NETWORK: `Une erreur s'est produite. Veuillez vérifier votre connexion et réessayer.`,
};

/**
 * Manejador de errores de cliente en las peticiones al API.
 * @param {Object} error
 * @param {number} error.status
 * @param {Object} error.data
 * @returns {Promise}
 */
const handleFetchClientError = async error => {
  const { status, data, logoutOnUnauthorized } = error;

  const isUnauthorized = status === STATUS_CODES.UNAUTHORIZED;
  if (isUnauthorized && logoutOnUnauthorized) {
    queryClient.clear();
    // Refresca la página para desloguear al usuario
    window.location.assign(window.location);
    return Promise.reject({ status, data });
  }

  const shouldLockScreen = HTTP_STATUS_CODES_TO_LOCK_UI.includes(status);
  if (shouldLockScreen) {
    notifyHttpStatusError({ status, data });
    return Promise.reject({ status, data });
  }

  const message = data?.message || data?.error || ERROR_MESSAGES.UNKNOWN;
  return Promise.reject({ ...error, message });
};

/**
 * Manejador de errores en las peticiones al API.
 * - Si es un error de red lanza un error con el mensaje humanizado.
 * - Si el error viene en la respuesta entonces lo controla y propaga el error devuelto.
 * - En cualquier otro caso propaga el error sin modificarlo.
 * @see https://developer.mozilla.org/es/docs/Web/HTTP/Status
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#checking_that_the_fetch_was_successful
 * @param {Object} error
 * @returns {Promise}
 */
const handleFetchError = error => {
  const isNetworkError = error instanceof TypeError;
  if (isNetworkError) {
    throw new Error(ERROR_MESSAGES.NETWORK);
  }

  const isUnknownError = !error.status;
  const isServerError = error.status >= 500;
  if (isUnknownError || isServerError) {
    const message = ERROR_MESSAGES.UNKNOWN;
    return Promise.reject({ ...error, message });
  }

  const isClientError = error.status >= 400;
  if (isClientError) {
    return handleFetchClientError(error);
  }

  return Promise.reject(error);
};

/**
 * Cliente para consumir datos del API
 * @see https://github.com/kentcdodds/bookshelf/blob/master/src/utils/api-client.js
 */
const client = ({
  baseUrl = apiUrl,
  request,
  data = request.body,
  format,
  logoutOnUnauthorized = true,
  ...rest
}) => {
  const url = `${baseUrl}${request.url}`;
  const options = parseRequestOptions({ request, data, format });

  return fetch(url, options)
    .then(async response => {
      const { status, ok } = response;
      const data = await response.json();

      if (!ok) {
        return Promise.reject({ status, data, logoutOnUnauthorized });
      }

      return data;
    })
    .catch(handleFetchError);
};

/**
 * Cliente para consumir ficheros del API
 * @see https://github.com/kentcdodds/bookshelf/blob/master/src/utils/api-client.js
 * @see https://stackoverflow.com/questions/16086162/handle-file-download-from-ajax-post
 */
const fileClient = ({
  baseUrl = apiUrl,
  request,
  data = request.body,
  format,
  logoutOnUnauthorized = true,
  ...rest
}) => {
  const url = `${baseUrl}${request.url}`;
  const options = parseRequestOptions({ request, data, format });

  return fetch(url, options)
    .then(async response => {
      const { status, ok } = response;
      const parseType = ok ? 'blob' : 'json';
      const data = await response[parseType]();

      if (!ok) {
        return Promise.reject({ status, data, logoutOnUnauthorized });
      }

      const disposition = response.headers.get('Content-Disposition');
      const contentType = response.headers.get('Content-Type');

      const defaultFilename = 'descarga';
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const [, rawFilename] =
        disposition && disposition.includes('attachment') ? filenameRegex.exec(disposition) : [];
      const filename = rawFilename ? rawFilename.replace(/"/g, '') : defaultFilename;

      return { contentType, data, filename };
    })
    .catch(handleFetchError);
};

export { client, fileClient };
