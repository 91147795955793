import React, { useContext, useReducer } from 'react';

const SnackbarStateContext = React.createContext();
const SnackbarDispatchContext = React.createContext();

const snackbarReducer = (state, action) => {
  const {
    type,
    message,
    timeout,
    icon,
    stacked,
    leading,
    dismissIcon = true,
    dismissesOnAction = true,
    actions,
    onClose,
  } = action;

  switch (type) {
    case 'open': {
      return {
        open: true,
        message,
        timeout,
        icon,
        stacked,
        leading,
        dismissIcon,
        dismissesOnAction,
        actions,
        onClose,
      };
    }
    case 'close': {
      return { open: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

const SnackbarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(snackbarReducer, { open: false });

  return (
    <SnackbarStateContext.Provider value={state}>
      <SnackbarDispatchContext.Provider value={dispatch}>
        {children}
      </SnackbarDispatchContext.Provider>
    </SnackbarStateContext.Provider>
  );
};

const useSnackbarState = () => {
  const context = useContext(SnackbarStateContext);
  if (context === undefined) {
    throw new Error('useSnackbarState must be used within a SnackbarProvider');
  }
  return context;
};

const useSnackbarDispatch = () => {
  const context = useContext(SnackbarDispatchContext);
  if (context === undefined) {
    throw new Error('useSnackbarDispatch must be used within a SnackbarProvider');
  }
  return context;
};

const useSnackbar = () => {
  return [useSnackbarState(), useSnackbarDispatch()];
};

export { SnackbarProvider, useSnackbarState, useSnackbarDispatch, useSnackbar };
