import React from 'react';
import ReactDOM from 'react-dom';

// Moment
import moment from 'moment';
import 'moment/locale/fr';

// React Dates
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

// React Material Web Components
// Se importa icon-button antes del snackbar dado que este lo usa
import '@rmwc/button/styles';
import '@rmwc/textfield/styles';
import '@rmwc/icon-button/styles';
import '@rmwc/snackbar/styles';
import '@rmwc/menu/styles';
import '@rmwc/tabs/styles';
import '@rmwc/select/styles';

// Tippy
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

// React Modern Drawer
import 'react-modern-drawer/dist/index.css';

import 'components/Actions/Actions.css';

import App from './App';
import AppProviders from './AppProviders';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

moment.locale('fr');

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
