import { useQuery } from 'react-query';
import { getToken } from 'services/auth';
import { fetchUser } from 'services/user';

const queryKeys = {
  authUser: ['authUser'],
};

const fetchUserIfTokenExists = () => {
  const token = getToken();
  if (!token) return Promise.resolve(null);
  return fetchUser();
};

const useQueryAuthUser = () => {
  const queryKey = queryKeys.authUser;
  const queryInfo = useQuery({
    queryKey,
    queryFn: fetchUserIfTokenExists,
  });
  return queryInfo;
};

export default useQueryAuthUser;
export { queryKeys };
