import moment from 'moment';
import orderBy from 'lodash/orderBy';
import { client } from 'clients/api';
import { unsubscribeFromNotifications } from 'utils/notifications';
import { parseRequestData } from 'utils/requests';

const LOCAL_STORAGE_TOKEN_KEY = 'token';

const fetchSessions = () => {
  const request = { url: '/auth/tokens', method: 'GET' };
  return client({ request })
    .then(response => response.map(parseSession))
    .then(sessions => sortSessions(sessions));
};

const parseSession = session => {
  const { headers } = session;
  const browserName = headers.userAgentParsed?.browser?.name;
  const osName = headers.userAgentParsed?.os?.name;
  const osVersion = headers.userAgentParsed?.os?.version;

  return { ...session, headers: { ...headers, browserName, osName, osVersion } };
};

const sortSessions = sessions => {
  return orderBy(sessions, ['current', 'lastUsedAt'], ['desc', 'desc']);
};

const getToken = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
};

/**
 * Hace login con los datos indicados.
 * Por defecto guarda el token de la respuesta.
 * @param {Object} arg
 * @param {Object} arg.data
 * @param {function} [arg.onFulfilled=handleLoginResponse] - Función para manejar la respuesta satisfactoria
 * @returns {Promise}
 */
const login = ({ data, onFulfilled = handleLoginResponse }) => {
  const request = { url: '/auth/login', method: 'POST' };
  return client({ request, data, logoutOnUnauthorized: false }).then(onFulfilled);
};

const loginWithUsernameAndPassword = ({ username, password }) => {
  const data = { username: username.toLowerCase(), password };
  return login({ data });
};

const loginWithOneTimePassword = ({ username, password, newPassword }) => {
  const data = { username: username.toLowerCase(), password, newPassword };
  return login({ data });
};

const handleLoginResponse = response => {
  const { token } = response;
  window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
  return response;
};

const requestPassword = ({ username }) => {
  const request = { url: '/auth/resetpassword', method: 'POST' };
  const data = { username: username.toLowerCase() };

  return client({ request, data, logoutOnUnauthorized: false });
};

const fetchLogout = () => {
  const request = { url: '/auth/logout', method: 'POST' };
  const data = {};
  return client({ request, data, logoutOnUnauthorized: false });
};

const logout = () => {
  window.localStorage.clear();
  window.location.assign('/');
  return unsubscribeFromNotifications();
};

/**
 * Actualiza la contraseña de un usuario
 * @param   {Object} args
 * @param   {string} args.id
 * @param   {string} args.oldPassword
 * @param   {string} args.newPassword
 * @returns {Promise}
 */
const updatePassword = ({ id, oldPassword, newPassword }) => {
  const request = { url: '/auth/events', method: 'POST', event: 'user.password.update' };
  const data = { id, oldPassword, newPassword };
  return client({ request, data });
};

/**
 * Reinicia la contraseña de un usuario
 * @param   {Object} args
 * @param   {string} args.id
 * @returns {Promise}
 */
const resetPassword = data => {
  const request = {
    url: '/auth/events',
    method: 'POST',
    event: 'user.password.reset',
  };
  return client({ request, data });
};

const resetAllPasswords = () => {
  const request = {
    url: '/auth/events',
    method: 'POST',
    event: 'users.password.reset',
    body: {},
  };
  return client({ request });
};

const unblockUser = data => {
  const request = {
    url: '/auth/events',
    method: 'POST',
    event: 'user.logincount.reset',
  };
  return client({ request, data });
};

/**
 * Genera un código pin dado el usuario
 * @param   {Object} args
 * @param   {string} args.id
 * @returns {Promise}
 */
const generatePincode = ({ id }) => {
  const request = { url: '/auth/events', method: 'POST', event: 'user.pincode.generate' };
  const data = { id };
  return client({ request, data });
};

/**
 *
 * @param   {Object} args
 * @param   {string} args.id
 * @param   {string} args.password
 * @param   {string} args.pincode
 * @returns {Promise}
 */
const updatePincode = ({ id, password, pincode }) => {
  const request = { url: '/auth/events', method: 'POST', event: 'user.pincode.update' };
  const data = { id, password, pinCode: pincode };
  return client({ request, data });
};

/**
 *
 * @param   {Object} args
 * @param   {string} args.id
 * @param   {string} args.deviceId
 * @param   {string} args.fingerprintId
 * @returns {Promise}
 */
const updateFingerprint = ({ id, deviceId, fingerprintId }) => {
  const request = { url: '/auth/events', method: 'POST', event: 'user.fingerprints.update' };
  const data = { id, deviceId, fingerprintId };
  return client({ request, data });
};

/**
 *
 * @param   {Object} args
 * @param   {string} args.id
 * @param   {string} args.amount
 * @returns {Promise}
 */
const updateCoins = ({ id, amount }) => {
  const request = { url: '/personas/events', method: 'POST', event: 'persona.coins.update' };
  const data = { id, amount };
  return client({ request, data });
};

/**
 * Elimina una sesión activa
 * @param   {Object} args
 * @param   {string} args._id
 * @returns {Promise}
 */
const removeSession = ({ _id }) => {
  const request = { url: '/auth/events', method: 'POST', event: 'token.remove' };
  const data = { _id };
  return client({ request, data });
};

const formForCertificate = { url: '/front/forms/userCertificate' };
const formForCertificateUpload = { url: '/front/forms/userCertificateUpload' };

/**
 * Consulta si el usuario puede generar un certificado.
 * Para poder generar un certificado, el usuario debe tener un teléfono móvil para enviarle un código de verificación.
 * @returns {Promise}
 */
const checkIfUserCanCreateCertificate = () => {
  const request = { url: '/personas/cancreatecertificate', method: 'GET' };
  return client({ request });
};

/**
 * Inicializa la generación de un certificado
 * @returns {Promise}
 */
const initCertificate = () => {
  const request = { url: '/auth/events', method: 'POST', event: 'user.certificate.init' };
  const data = {};
  return client({ request, data });
};

/**
 * Genera un certificado
 * @param   {Object}  args
 * @param   {string}  args.viewBox
 * @param   {string}  args.svgString
 * @param   {string}  args.certificateToken
 * @param   {string}  args.password
 * @returns {Promise}
 */
const createCertificate = ({ viewBox, svgString, certificateToken, password }) => {
  const request = { url: '/auth/events', method: 'POST', event: 'user.certificate.create' };
  const data = { viewBox, svgString, certificateToken, password };
  return client({ request, data });
};

/**
 * Sube un certificado externo
 * @param   {Object}  args
 * @param   {string}  args.viewBox
 * @param   {string}  args.svgString
 * @param   {string}  args.certificateToken
 * @param   {string}  args.password
 * @param   {File}    args.file
 * @returns {Promise}
 */
const uploadCertificate = ({ viewBox, svgString, certificateToken, password, file }) => {
  const request = { url: '/auth/events', method: 'POST' };
  const values = {
    viewBox,
    svgString,
    certificateToken,
    password,
    file,
    event: 'user.certificate.upload',
  };
  const format = 'formData';
  const data = parseRequestData({ values, format });
  return client({ request, data, format });
};

/**
 * Elimina un certificado
 * @returns {Promise}
 */
const removeCertificate = data => {
  const request = { url: '/auth/events', method: 'POST', event: 'user.certificate.remove' };
  return client({ request, data });
};

/**
 * Devuelve el estado de un certificado
 * @param   {Object} args
 * @param   {Object} args.certificate
 * @returns {string}
 */
const getCertificateState = ({ certificate = {} }) => {
  const { expiresAt, docId } = certificate;
  const hasExpired = expiresAt && moment().isAfter(expiresAt);
  const isFullfilled = Boolean(docId);

  if (hasExpired) return 'expired';
  if (isFullfilled) return 'fullfilled';
  return 'ignore';
};

/**
 * Reinicia la política de privacidad
 * @returns {Promise}
 */
const resetPrivacy = data => {
  const request = {
    url: '/auth/events',
    method: 'POST',
    event: 'users.privacyterms.reset',
  };
  return client({ request, data });
};

const formForResetPrivacyUsers = { url: '/front/forms/resetPrivacyUsers' };

export {
  fetchSessions,
  getToken,
  login,
  loginWithUsernameAndPassword,
  loginWithOneTimePassword,
  requestPassword,
  fetchLogout,
  logout,
  updatePassword,
  resetPassword,
  resetAllPasswords,
  unblockUser,
  generatePincode,
  updatePincode,
  updateFingerprint,
  updateCoins,
  removeSession,
  formForCertificate,
  formForCertificateUpload,
  checkIfUserCanCreateCertificate,
  initCertificate,
  createCertificate,
  uploadCertificate,
  removeCertificate,
  getCertificateState,
  resetPrivacy,
  formForResetPrivacyUsers,
};
