import moment from 'moment';
import { client, fileClient } from 'clients/api';
import { parseRequestData } from 'utils/requests';
import { parseUser } from './users';

const LOCAL_STORAGE_USER_KEY = 'userId';

const fetchUser = () => {
  const request = { url: `/auth/mine` };
  return client({ request })
    .then(handleUserResponse)
    .then(parseUser);
};

// Guarda el id del usuario en el localStorage para que pueda ser consultado por Docview...
const handleUserResponse = response => {
  const { id } = response;
  window.localStorage.setItem(LOCAL_STORAGE_USER_KEY, id);
  return response;
};

const fetchUnreads = () => {
  const request = { url: '/custom/unreads' };
  return client({ request }).then(parseUnreads);
};

const fetchHome = () => {
  const request = { url: '/personas/home' };
  return client({ request });
};

const fetchResumeHours = ({ filterData }) => {
  const request = {
    url: '/personas/acumulatedgraph/filter',
    method: 'POST',
    body: { filterData },
  };
  return client({ request });
};

const fetchResumeHoursFile = ({ prevMonth }) => {
  const request = { url: '/personas/events', method: 'POST', event: 'persona.resume.print' };
  const data = { prevMonth };
  return fileClient({ request, data });
};

const fetchWorkedTime = () => {
  const request = { url: '/personas/workedtime' };
  return client({ request });
};

const parseUnreads = response => {
  const [data] = response;
  const notifications = data?.notifications.map(parseUnreadNotification);
  return { ...data, notifications };
};

const parseUnreadNotification = data => {
  const { createdAt } = data;
  const createdAtParsed = createdAt && moment(createdAt).format('DD/MM/YYYY HH:mm');
  return { ...data, createdAtParsed };
};

/**
 * Actualiza los datos de un usuario
 * @param   {Object} args
 * @param   {string} args.id
 * @param   {string} args.email
 * @param   {string} args.firstName
 * @param   {string} args.lastName
 * @param   {string} args.gender
 * @returns {Promise}
 */
const updateUser = ({ id, email, firstName, lastName, gender }) => {
  const request = { url: '/personas/events', method: 'POST', event: 'persona.update' };
  const data = { id, email, firstName, lastName, gender };
  return client({ request, data });
};

/**
 * Actualiza una acreditación
 * @param   {Object}  args
 * @param   {string}  args.skillId
 * @param   {file}    args.file
 * @param   {string}  args.expires
 * @returns {Promise}
 */
const updateSkill = ({ skillId, file, expires = null }) => {
  const request = { url: '/docs/upload', method: 'POST' };
  const values = { skillId, file, expires, rule: 'persona.skill.request' };
  const format = 'formData';
  const data = parseRequestData({ values, format });
  return client({ request, data, format });
};

/**
 * Elimina una acreditación
 * @param   {Object} args
 * @param   {string} args.skillId
 * @returns {Promise}
 */
const removeSkill = ({ skillId }) => {
  const request = { url: '/personas/events', method: 'POST', event: 'persona.skill.remove' };
  const data = { skillId };
  return client({ request, data });
};

/**
 * Devuelve el estado de una acreditación
 * @param   {Object} args
 * @param   {Object} args.skill
 * @returns {string}
 */
const getSkillState = ({ skill }) => {
  const { fullfilled, expires, required } = skill;
  const hasExpired = fullfilled && skill.expires && moment().isAfter(expires);

  if (hasExpired) return 'expired';
  if (fullfilled) return 'fulfilled';
  if (required) return 'required';
  return 'empty';
};

/**
 * Actualiza el estado de ánimo del usuario
 * @param   {Object} args
 * @param   {string} args.id
 * @returns {Promise}
 */
const setFeeling = ({ id }) => {
  const request = { url: '/personas/events', method: 'POST', event: 'persona.feeling.set' };
  const data = { id };
  return client({ request, data });
};

/**
 * Devuelve el porcentaje de tiempo trabajado
 * @param {Object} arg
 * @param {Object} arg.journal
 * @param {Object} arg.workedTime
 * @returns {number}
 */
const getWorkedPercentage = ({ journal, workedTime }) => {
  if (!journal?.amount || !workedTime?.amount) return 0;
  return Math.round((workedTime?.amount / journal?.amount) * 100);
};

export {
  fetchUser,
  fetchUnreads,
  fetchHome,
  fetchResumeHours,
  fetchResumeHoursFile,
  fetchWorkedTime,
  updateUser,
  updateSkill,
  removeSkill,
  getSkillState,
  setFeeling,
  getWorkedPercentage,
};
