import orderBy from 'lodash/orderBy';
import { client } from 'clients/api';
import { filterDataBySearchText } from 'utils/search';
import { formatDateString } from 'utils/dates';

const fetchUsers = ({ role, residence_id }) => {
  let url = `/users`;
  if (role) url += `?role=${role}`;
  if (residence_id) url += `&residence_id=${residence_id}`;

  const request = { url };
  return client({ request }).then(parseUsers);
};

const parseUsers = users => {
  return orderBy(users, ['name'], ['asc']);
};

const fetchUser = ({ _id }) => {
  const request = { url: `/users/${_id}` };
  return client({ request }).then(parseUser);
};

const parseUser = user => {
  const { begin, end, birthday, residences, managers, skills, notes } = user;
  const beginFormatted = formatDateString({ date: begin });
  const endFormatted = formatDateString({ date: end });
  const birthdayFormatted = formatDateString({ date: birthday });

  return {
    ...user,
    beginFormatted,
    endFormatted,
    birthdayFormatted,
    residences: parseResidences(residences),
    managers: parseManagers(managers),
    skills: parseSkills(skills),
    notes: parseNotes(notes),
  };
};

const parseResidences = residences => {
  return residences?.map(residence => {
    const { date } = residence;
    const dateFormatted = formatDateString({ date });
    return { ...residence, dateFormatted };
  });
};

const parseManagers = managers => {
  return managers?.map(manager => {
    const { date } = manager;
    const dateFormatted = formatDateString({ date });
    return { ...manager, dateFormatted };
  });
};

const parseNotes = notes => {
  return notes?.map(note => {
    const { date } = note;
    const dateFormatted = formatDateString({ date });
    return { ...note, dateFormatted };
  });
};

const parseSkills = skills => {
  return skills?.map(skill => {
    const { certification_date, review_date } = skill;
    const certification_date_formatted = formatDateString({ date: certification_date });
    const review_date_formatted = formatDateString({ date: review_date });
    return { ...skill, certification_date_formatted, review_date_formatted };
  });
};

/**
 * Crea usuario
 * @param   {Object}  args
 * @returns {Promise}
 */
const createUser = data => {
  const request = { url: '/users', method: 'POST' };
  return client({ request, data });
};

/**
 * Actualiza usuario
 * @param   {Object}  args
 * @returns {Promise}
 */
const updateUser = data => {
  const request = { url: `/users/${data?._id}`, method: 'PUT' };
  return client({ request, data });
};

/**
 * Actualiza usuario
 * @param   {Object}  args
 * @returns {Promise}
 */
const updateUserPassword = data => {
  const request = { url: `/users/changepassword`, method: 'PUT' };
  return client({ request, data });
};

/**
 * Elimina usuario
 * @param   {Object}  args
 * @returns {Promise}
 */
const removeUser = data => {
  const request = { url: `/users/${data?._id}`, method: 'DELETE' };
  return client({ request, data });
};

const filterUsersBySearchText = ({ data, searchText }) => {
  const keys = ['name'];
  return filterDataBySearchText({ data, searchText, keys });
};

export {
  fetchUsers,
  fetchUser,
  createUser,
  updateUser,
  updateUserPassword,
  removeUser,
  filterUsersBySearchText,
  parseUser,
};
