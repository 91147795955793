// https://system-ui.com/theme/
// https://tailwindcss.com/docs/customizing-colors#default-color-palette
// https://chakra-ui.com/theme#colors

// @TODO: Separar en variables

const theme = {
  colors: {
    black: '#000000',
    white: '#ffffff',
    gray: '#f5f5f5',
    darkGray: '#757575',
    mediumGray: '#b5b5b5',
    lavender: '#E4E8F5',
    lavenderSoft: '#f8faff',
    cadetBlue: '#ACB1C1',
    trout: '#52555F',
    emerald: '#1be86d',
    green: '#65e296',
    primary: '#3e72df',
    primarySoft: '#edf2fc',
    secondary: '#ff9726',
    success: '#1ef59f',
    warning: '#ffc24d',
    error: '#f44336',
    info: '#00b0ff',
    leaves: '#9026f9',
    overtimes: '#00b0ff',
    debtTimes: '#f44034',
    vacations: '#f9ae55',
    swaps: '#1ef59f',
    vacationSwaps: '#ff6838',
  },

  gradients: {
    yellow: { from: '#FFEA7D', to: '#FFCF97' },
    orange: { from: '#FFA664', to: '#FFD28F' },
    secondary: { from: '#ff6b00', to: '#ffc24d' },
    red: { from: '#ff7d9c', to: '#f8763e' },
    blood: { from: '#ff4444', to: '#ff9497' },
    sakura: { from: '#ed728b', to: '#a15b88' },
    pink: { from: '#FC8ADC', to: '#FFB7B7' },
    mauve: { from: '#c0b9db', to: '#e3b6d2' },
    purple: { from: '#B67DFF', to: '#EF8FFF' },
    indigo: { from: '#7643ed', to: '#9d80e7' },
    space: { from: '#130c6f', to: '#B67DFF' },
    blue: { from: '#7643ed', to: '#a9eeff' },
    sky: { from: '#a9eeff', to: '#00b2ff' },
    lapislazuli: { from: '#34b4e5', to: '#3ed3b4' },
    mint: { from: '#3ed3b4', to: '#a7ff7d' },
    green: { from: '#a7ff7d', to: '#8fffe4' },
    lime: { from: '#75D384', to: '#DFF87B' },
    jade: { from: '#7cbba8', to: '#b8d8c0' },
    wood: { from: '#98be93', to: '#7c956f' },
    bronze: { from: '#bea193', to: '#ffe6cb' },
    gray: { from: '#EBEBEB', to: '#CFCED9' },
    black: { from: '#A1A1A1', to: '#606060' },
    white: { from: '#ECE9E6', to: '#fefefe' },
  },

  randomColors: [
    '#2dcd85',
    '#B67DFF',
    '#f8763e',
    '#00b0ff',
    '#ffc24d',
    '#d23719',
    '#1121ab',
    '#e90f66',
    '#8bcbb5',
    '#f74845',
    '#cf826b',
    '#c6ec2f',
    '#16514d',
  ],

  fonts: {
    heading: 'Roboto, sans-serif',
    body: 'Roboto, sans-serif',
    mono: 'Roboto Mono, monospace',
  },

  fontSizes: {
    '2xs': '0.625rem',
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '4rem',
  },

  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    bold: 700,
  },

  lineHeights: {},

  space: {},

  sizes: {},

  shadows: {},

  zIndices: {},
};

export default theme;
